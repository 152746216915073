import axios from 'axios'

axios.defaults.baseUrl = process.env.API_URL
axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = 'X-CSRF-Token'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.headers.common['X-Acquire-Front-End-Build-Number'] = process.env.BUILD_NUMBER

// axios.interceptors.request.use(
// 	(request) => {
// 		console.info('honk', request.headers)
// 		return request
// 	},
// 	(error) => Promise.reject(error),
// )

axios.interceptors.response.use(
	(response) => {
		// console.info('blarg', response.headers)
		if (response.headers['x-acquire-highest-front-end-build-number'])
			sessionStorage.setItem(
				'highest_front_end_build_number',
				response.headers['x-acquire-highest-front-end-build-number'],
			)
		return response
	},
	(error) => Promise.reject(error),
)
