import { gql } from '@apollo/client'
import { userFragments, userTimeOffFragments, timeOffFragments, commissionGoalFragments } from './fragments'

export const GET_CURRENT_USER = gql`
	query current_user {
		current_user {
			id
			lead_jig_user_id
			active
			department
			role
			full_name
			first_name
			last_name
			email
			is_client
			is_account
			is_administration
			is_sales
			is_data
			is_artist
			is_digital
			is_upwork
			is_production
			is_billing
			is_management
			is_god

			has_click_up_access_token

			force_logout

			clients {
				id
				name
			}

			fmos {
				id
				name
			}
		}
	}
`

export const UPDATE_USER = gql`
	mutation updateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			user {
				...UserData
			}
		}
	}
	${userFragments}
`

export const GET_USERS = gql`
	query users($department: String, $action: String) {
		users(department: $department, action: $action) {
			...UserData
		}
	}
	${userFragments}
`

export const GET_USERS_TABLE = gql`
	query users_table($active: Boolean, $page_size: Int!, $page: Int!, $filters: JSON, $sorts: JSON, $user_id: ID, $user_ids: [ID!]) {
		users_table(active: $active, page_size: $page_size, page: $page, filters: $filters, sorts: $sorts, user_id: $user_id, user_ids: $user_ids) {
			_total
			_total_pages
			users {
				...UserData
				commission_goals {
					...CommissionGoalData
				}
			}
		}
	}
	${userFragments}
	${commissionGoalFragments}
`


export const GET_USER_TIME_OFF = gql`
	query user($id: ID!) {
		user(id: $id) {
			full_name
			...UserTimeOffData
			time_offs {
				...TimeOffData
			}
		}
	}
	${userTimeOffFragments}
	${timeOffFragments}
`
