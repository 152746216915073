/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import { Routes, Route, useLocation, useNavigate, useMatch } from 'react-router-dom'
import { useWindowSize, useCurrentUser, usePermissionCheck } from 'hooks'

import { ToastContainer } from 'react-toastify'

import { AppLoader, InlineLoader } from 'Layout/AppLoader'
import AppHeader from 'Layout/AppHeader'
import AppSidebar from 'Layout/AppSidebar'

import classNames from 'classnames'
import GlobalStyle from 'global-styles'

import NewVersionBanner from 'components/Banners/NewVersion'
const DrinkAlert = lazy(() => import('components/DrinkAlert'))

const Dashboard = lazy(() => import('components/Dashboard'))
const TaskList = lazy(() => import('components/TaskList/Index'))
const Calendar = lazy(() => import('components/Calendar'))
const Orders = lazy(() => import('components/Orders/Index'))
const OrderShow = lazy(() => import('components/Orders/Show'))
const OrderDigital = lazy(() => import('components/Orders/Digital'))
const PerformanceCheckout = lazy(() => import('components/PerformanceCheckout'))
const InformedVisibility = lazy(() => import('components/InformedVisibility/Show'))
const Subscriptions = lazy(() => import('components/Subscriptions/Index'))
const Users = lazy(() => import('components/Users/Index'))
const Clients = lazy(() => import('components/Clients/Index'))
const ClientPaymentMethods = lazy(() => import('components/Clients/PaymentMethods'))
const ClientOrdersIndex = lazy(() => import('components/Clients/Orders/Index'))
const TimeOff = lazy(() => import('components/TimeOff'))
const Config = lazy(() => import('components/Config'))
const Venues = lazy(() => import('components/Venues/Index'))
const Venue = lazy(() => import('components/Venues/Show'))
const Vendors = lazy(() => import('components/Vendors/Index'))
const Accounts = lazy(() => import('components/Accounts/Index'))
const MailerFormats = lazy(() => import('components/MailerFormats/Index'))
const PostOffices = lazy(() => import('components/PostOffices/Index'))
const BlackoutDates = lazy(() => import('components/BlackoutDates/Index'))
const Topics = lazy(() => import('components/Topics/Index'))
const Objectives = lazy(() => import('components/Objectives/Index'))
const VenueBrands = lazy(() => import('components/Venues/Brands/Index'))
const VenueTypes = lazy(() => import('components/VenueTypes/Index'))
const VenueAmenityClass = lazy(() => import('components/VenueAmenityClasses/Index'))
const SubscriptionServices = lazy(() => import('components/SubscriptionServices/Index'))
const MailerTemplates = lazy(() => import('components/MailerTemplates/Index'))
const MailerTemplate = lazy(() => import('components/MailerTemplates/Edit'))
const DigitalTemplates = lazy(() => import('components/DigitalTemplates/Index'))
const DigitalTemplateImages = lazy(() => import('components/DigitalTemplates/Images/Index'))
const AccountingProducts = lazy(() => import('components/Accounting/Products/Index'))
const CommissionableOrders = lazy(() => import('components/Accounting/CommissionReports/Outstanding'))
const UnpaidInvoices = lazy(() => import('components/Accounting/CommissionReports/Unpaid'))
const CommissionReports = lazy(() => import('components/Accounting/CommissionReports/Index'))
const CommissionReport = lazy(() => import('components/Accounting/CommissionReports/Show'))
const SalesSummary = lazy(() => import('components/SalesSummary'))
const Invoices = lazy(() => import('components/Invoices/Index')) // todo: Move under Accounting folder
const Transactions = lazy(() => import('components/Transactions/Index'))
const DigitalOnly = lazy(() => import('components/Accounting/DigitalOnly'))
const DigitalProof = lazy(() => import('components/Pdf/Digital'))
const AdminOrderForm = lazy(() => import('components/Pdf/AdminOrderForm'))
const OrderForm = lazy(() => import('components/Pdf/OrderForm'))
const OrderFormClientView = lazy(() => import('components/Pdf/OrderForm/ClientView'))
const VendorWorkOrder = lazy(() => import('components/Pdf/VendorWorkOrder'))
const Dropbox = lazy(() => import('components/Dropbox'))

const NotFoundPage = () => <span>Page Not Found</span>

const AppMainOuter = styled.div`
	transition: padding-left 0.2s;
	padding-left: ${({ expanded, no_sidebar }) => (no_sidebar ? 0 : expanded ? 225 : 64)}px !important;
`

function App() {
	const { width } = useWindowSize()
	const [expanded, setExpanded] = useState(true)
	const [new_version_banner_showing, setNewVersionBannerShowing] = useState(false)
	const current_user = useCurrentUser()
	const can_index_commissions = usePermissionCheck(current_user.id, 'index', 'CommissionReport')
	const can_view_dashboard = usePermissionCheck(current_user.id, 'view_dashboard', 'User')
	const can_view_invoice = usePermissionCheck(current_user.id, 'view', 'Invoice')
	const can_view_transactions = usePermissionCheck(current_user.id, 'view', 'Accounting::Transaction')

	const can_index_mailer_formats = usePermissionCheck(current_user.id, 'index', 'MailerFormat')
	const can_index_post_offices = usePermissionCheck(current_user.id, 'index', 'PostOffice')
	const can_index_blackout_dates = usePermissionCheck(current_user.id, 'index', 'BlackoutDate')
	const can_index_topics = usePermissionCheck(current_user.id, 'index', 'Topic')
	const can_index_objectives = usePermissionCheck(current_user.id, 'index', 'Objective')
	const can_index_venue_brands = usePermissionCheck(current_user.id, 'index', 'VenueBrand')
	const can_index_venue_types = usePermissionCheck(current_user.id, 'index', 'VenueType')
	const can_index_venue_amenity_classes = usePermissionCheck(current_user.id, 'index', 'VenueAmenityClass')
	const can_index_subscription_services = usePermissionCheck(current_user.id, 'index', 'SubscriptionService')
	const can_index_digital_templates = usePermissionCheck(current_user.id, 'index', 'DigitalTemplate')
	const can_index_digital_template_images = usePermissionCheck(current_user.id, 'index', 'DigitalTemplateImage')
	const can_index_mailer_templates = usePermissionCheck(current_user.id, 'index', 'MailerTemplate')

	const can_view_config =
		can_view_invoice ||
		can_index_mailer_formats ||
		can_index_post_offices ||
		can_index_blackout_dates ||
		can_index_topics ||
		can_index_objectives ||
		can_index_venue_brands ||
		can_index_venue_types ||
		can_index_venue_amenity_classes ||
		can_index_subscription_services ||
		can_index_digital_templates ||
		can_index_digital_template_images ||
		can_index_mailer_templates

	const digital_pdf_proof = useMatch('/orders/:id/digital')

	const { pathname } = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	useEffect(() => {
		if (width <= 1280) {
			setExpanded(false)
		} else {
			setExpanded(true)
		}
	}, [width])

	useEffect(() => {
		// todo: Shouldn't those startsWith include a starting `/`
		console.info({pathname})
		if (
			(current_user?.is_client || current_user?.is_account) &&
			!pathname.startsWith(`/${current_user.id}/orders`) &&
			!pathname.startsWith('/payment_methods/') &&
			!pathname.startsWith('/orders/')
		) {
			if (current_user.is_client) {
				navigate(`/client/${current_user.id}/orders`)
			} else if (current_user.is_account) {
				navigate(`/fmo/${current_user.id}/orders`)
			}
		}
	}, [current_user?.is_client, current_user?.is_account])

	// const can_index_vendors = usePermissionCheck(current_user.id, 'index', 'Vendor')
	// const can_index_accounts = usePermissionCheck(current_user.id, 'index', 'Account')
	// const can_index_mailer_formats = usePermissionCheck(current_user.id, 'index', 'MailerFormat')
	// const can_index_topics = usePermissionCheck(current_user.id, 'index', 'Topic')
	// const can_index_objectives = usePermissionCheck(current_user.id, 'index', 'Objective')
	// const can_index_digital_templates = usePermissionCheck(current_user.id, 'index', 'DigitalTemplate')

	if (pathname.startsWith('/ftp_fc')) {
		return (
			<Suspense fallback={<InlineLoader />}>
				<Dropbox />
			</Suspense>
		)
	}

	if (!current_user || !current_user.id) {
		return <AppLoader />
	}

	if (current_user.id && (!current_user.active || !current_user.department)) {
		window.location = `${process.env.API_URL}/unauthorized`
	}

	if (digital_pdf_proof) {
		return (
			<Suspense fallback={<InlineLoader />}>
				<Routes>
					<Route path="/orders/:id/digital" element={<DigitalProof preview />} />
				</Routes>
			</Suspense>
		)
	}

	if (!current_user.is_client && !current_user.is_account && current_user.department) {
		return (
			<div
				className={classNames(
					'app-container',
					'app-theme-white',
					'fixed-header',
					'fixed-footer',
					'sidebar-mobile-open',
					'body-tabs-shadow-btn',
					'fixed-sidebar',
				)}
			>
				<NewVersionBanner setNewVersionBannerShowing={setNewVersionBannerShowing} />
				<AppHeader new_version_banner_showing={new_version_banner_showing} />
				<div className="app-main" style={{ paddingTop: new_version_banner_showing ? 60 + 35 : 60 }}>
					<AppSidebar expanded={expanded} setExpanded={setExpanded} />
					<AppMainOuter className="app-main__outer" expanded={expanded} no_sidebar={width < 665}>
						<div className="app-main__inner">
							<Suspense fallback={<InlineLoader />}>
								<Routes>
									<Route path="/" element={<TaskList />} />
									{can_view_dashboard && <Route path="/dashboard" element={<Dashboard />} />}
									<Route path="/tasks" element={<TaskList />} />
									<Route path="/calendar" element={<Calendar />} />
									<Route path="/orders" element={<Orders />} />
									<Route path="/orders/:id" element={current_user.is_upwork ? <OrderDigital /> : <OrderShow />} />
									<Route path="/iv/:id" element={<InformedVisibility />} />
									<Route path="/subscriptions" element={<Subscriptions />} />
									<Route path="/orders/:id/admin_order_form" element={<AdminOrderForm preview />} />
									<Route path="/orders/:id/order_form" element={<OrderForm preview />} />
									<Route path="/orders/:id/digital" element={<DigitalProof preview />} />
									<Route path="/orders/:id/vendor_work_order" element={<VendorWorkOrder preview />} />
									<Route path="/checkout/:lead_jig_campaign_id/:lead_jig_event_id" element={<PerformanceCheckout />} />
									<Route path="/venues" element={<Venues />} />
									<Route path="/venues/:id" element={<Venue />} />
									<Route path="/users" element={<Users />} />
									<Route path="/clients" element={<Clients />} />
									<Route path="/pto" element={<TimeOff />} />
									<Route path="/pto/:user_id" element={<TimeOff />} />
									<Route path="/:type/:id/orders" element={<ClientOrdersIndex />} />
									<Route path="/:type/:id/payment_methods" element={<ClientPaymentMethods />} />
									<Route path="/vendors" element={<Vendors />} />
									<Route path="/accounts" element={<Accounts />} />
									<Route path="/sales_summary" element={<SalesSummary />} />
									<Route path="/sales_summary/:user_id" element={<SalesSummary />} />
									{can_index_commissions && (
										<React.Fragment>
											<Route path="/commission" element={<CommissionableOrders />} />
											<Route path="/commission/outstanding" element={<CommissionableOrders />} />
											<Route path="/commission/unpaid" element={<UnpaidInvoices />} />
											<Route path="/commission/reports" element={<CommissionReports />} />
											<Route path="/commission/reports/:id" element={<CommissionReport />} />
										</React.Fragment>
									)}
									{can_view_invoice && (
										<React.Fragment>
											<Route path="/accounting" element={<AccountingProducts />} />
											<Route path="/accounting/products" element={<AccountingProducts />} />
											<Route path="/accounting/invoices" element={<Invoices />} />
											{can_view_transactions && (
												<Route path="/accounting/transactions" element={<Transactions />} />
											)}
											<Route path="/accounting/digital_only" element={<DigitalOnly />} />
										</React.Fragment>
									)}
									{can_view_config && (
										<Route path="/config">
											<Route path="/" element={<Config />} />
											{can_index_mailer_templates && (
												<React.Fragment>
													<Route path="/mailer_templates" element={<MailerTemplates />} />
													<Route path="/mailer_templates/:id" element={<MailerTemplate />} />
												</React.Fragment>
											)}
											{can_index_digital_templates && (
												<Route path="/digital_templates" element={<DigitalTemplates />} />
											)}
											{can_index_digital_template_images && (
												<Route
													path="/digital_template_images"
													element={<DigitalTemplateImages />}
												/>
											)}
											{can_index_mailer_formats && (
												<Route path="/mailer_formats" element={<MailerFormats />} />
											)}
											{can_index_post_offices && (
												<Route path="/post_offices" element={<PostOffices />} />
											)}
											{can_index_blackout_dates && (
												<Route path="/blackout_dates" element={<BlackoutDates />} />
											)}
											{can_index_topics && <Route path="/topics" element={<Topics />} />}
											{can_index_objectives && (
												<Route path="/objectives" element={<Objectives />} />
											)}
											{can_index_venue_brands && (
												<Route path="/venue_brands" element={<VenueBrands />} />
											)}
											{can_index_venue_types && (
												<Route path="/venue_types" element={<VenueTypes />} />
											)}
											{can_index_venue_amenity_classes && (
												<Route path="/venue_amenity_classes" element={<VenueAmenityClass />} />
											)}
											{can_index_subscription_services && (
												<Route
													path="/subscription_services"
													element={<SubscriptionServices />}
												/>
											)}
										</Route>
									)}
									<Route path="*" element={<NotFoundPage />} />
								</Routes>
							</Suspense>
						</div>
					</AppMainOuter>
				</div>
				<Suspense fallback={null}>
					<DrinkAlert />
				</Suspense>
				<ToastContainer />
				<Global styles={GlobalStyle} />
			</div>
		)
	}

	if (current_user.is_client || current_user.is_account) {
		return (
			<div
				className={classNames(
					'app-container',
					'app-theme-white',
					'fixed-header',
					'fixed-footer',
					'sidebar-mobile-open',
					'body-tabs-shadow-btn',
					'fixed-sidebar',
				)}
			>
				<NewVersionBanner setNewVersionBannerShowing={setNewVersionBannerShowing} />
				<AppHeader new_version_banner_showing={new_version_banner_showing} />
				<div
					className="app-main"
					style={{ paddingLeft: 0, paddingRight: 0, paddingTop: new_version_banner_showing ? 60 + 35 : 60 }}
				>
					<AppMainOuter className="app-main__outer" no_sidebar>
						<div className="app-main__inner">
							<Suspense fallback={<InlineLoader />}>
								<Routes>
									<Route path="/:type/:id/orders" element={<ClientOrdersIndex />} />
									<Route path="/:type/:id/payment_methods" element={<ClientPaymentMethods />} />
									<Route path="/checkout/:lead_jig_campaign_id/:lead_jig_event_id" element={<PerformanceCheckout />} />
									<Route path="/orders/:id/order_form" element={<OrderFormClientView />} />
								</Routes>
							</Suspense>
						</div>
					</AppMainOuter>
				</div>
				<ToastContainer />
				<Global styles={GlobalStyle} />
			</div>
		)
	}
}

export default App
